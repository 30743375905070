import React from "react"

import { PoemFrontMatterContents, NextPrev } from "../types/poemTypes"
import { useScrollToTop } from "../hooks/useScrollToTop"
import { Layout } from "../components/Layout"
import { PoemContent } from "../components/PoemContent"

type Context = PoemFrontMatterContents & {
  next: NextPrev
  prev: NextPrev
}

type Props = {
  pageContext: Context
  data: any
}

const Poem: React.FC<Props> = ({ pageContext }) => {
  const { poem, annotations, next, prev } = pageContext

  useScrollToTop()

  if (!poem) return null

  const title = `Muetry - ${poem.attributes.title}.`

  return (
    <Layout title={title}>
      <PoemContent
        poem={poem}
        annotations={annotations}
        next={next}
        prev={prev}
      />
    </Layout>
  )
}

export default Poem
