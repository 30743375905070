import styled from "styled-components"

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 800px;
  margin: 5rem auto;
  overflow: auto;
`

export const Home = styled.a`
  position: absolute;
  top: 1rem;
  left: 1rem;
  color: inherit;
  text-decoration: none;
  opacity: 0.6;

  &:hover {
    opacity: 1;
  }
`

export const Header = styled.div`
  flex-shrink: 0;
  padding-bottom: 3rem;
  text-align: center;
`

export const Title = styled.h1`
  margin-top: 0;
  margin-bottom: 0;
  font-size: 2.5rem;
  text-shadow: 1px 1px 3px white;
`

export const Page = styled.div`
  font-size: 1.125rem;
  line-height: 1.5;
  text-align: center;
  padding: 5rem 1.5rem 10rem;
  white-space: pre-wrap;
  border-top: 2px solid black;
  border-bottom: 2px solid black;
  display: flex;
  flex-direction: column;
`
