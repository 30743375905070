import styled from "styled-components"

export const Highlight = styled.span`
  background: #ddd;
  cursor: pointer;
`

export const Kaleidoscope = styled.iframe`
  width: 100%;
  height: 300px;
  border: 0;
  overflow: hidden;
`

const Delim = styled.span`
  display: inline-block;
  text-shadow: 3px 3px 2px rgba(74, 39, 28, 0.2);
`

export const TopDelim = styled(Delim)`
  margin-bottom: 1rem;
`

export const BottomDelim = styled(Delim)`
  margin-top: 1rem;
`

export const Datestamp = styled.p`
  margin-top: 2rem;
  font-size: 0.75rem;
  letter-spacing: 1px;
`

export const Links = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 2rem;
`

export const PaginationLink = styled.a`
  display: inline-block;
  width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: inherit;
  font-weight: bold;
  text-decoration: none;
  opacity: 0.6;
`

export const AnnotationBox = styled.div`
  padding: 1rem 1rem 0.5rem;
  text-align: justify;
  line-height: 1.5;
  display: flex;
  flex-direction: column;
  background: white;

  position: fixed;
  bottom: 0;
  left: 50%;
  width: 90vw;
  transform: translateX(-50%);
  border-top: 2px solid black;
  border-left: 2px solid black;
  border-right: 2px solid black;

  @media (min-width: 1200px) {
    left: unset;
    transform: unset;
    position: fixed;
    top: 0;
    right: 0;
    height: 100vh;
    max-width: calc((100vw - 800px) / 2);
    border-left: 2px solid black;
    border-top: 0;
    border-right: 0;
  }
`

export const AnnotationContent = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const Close = styled.button`
  align-self: flex-end;
  background: transparent;
  border: 0;
  text-decoration: underline;
  cursor: pointer;
`
