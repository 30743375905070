import React, { useState, ReactNode } from "react"
import ReactMarkdown from "react-markdown"
import { format } from "date-fns"

import { getCoordQS } from "../utils/coords"
import { Poem, Annotation, NextPrev } from "../types/poemTypes"
import { Content, Home, Header, Title, Page } from "./PageContent"
import {
  Kaleidoscope,
  TopDelim,
  BottomDelim,
  Datestamp,
  Highlight,
  Links,
  PaginationLink,
  AnnotationBox,
  AnnotationContent,
  Close,
} from "./PoemContent.style"

type Props = {
  poem: Poem
  annotations: Annotation[]
  next: NextPrev
  prev: NextPrev
}

export const PoemContent: React.FC<Props> = ({ poem, annotations, next, prev }) => {
  const [annotation, setAnnotation] = useState<Annotation>()

  const findAnnotation = (value: string) => () => {
    const anno = annotations.find(
      (a) => a.attributes.content.toLowerCase() === value.toLowerCase()
    )
    if (anno) {
      setAnnotation(anno)
    }
  }

  const components = {
    code: ({ children }: { children: ReactNode[] }) => {
      const value = children[0]?.toString() || ""
      return <Highlight onClick={findAnnotation(value)}>{value}</Highlight>
    },
  }

  const formattedDate = format(new Date(poem.attributes.date), "MMMM do yyyy")

  return (
    <Content>
      <Home href="/">Muetry.</Home>
      <Kaleidoscope
        src={`https://jamesformica.github.io/kaleidoscope/muetry.html?${getCoordQS(
          poem.attributes.title
        )}`}
      />

      <Page>
        <Header>
          <Title>{poem.attributes.title}.</Title>
        </Header>
        <TopDelim>○○○</TopDelim>
        <ReactMarkdown children={poem.body} components={components} />
        <BottomDelim>○○○</BottomDelim>
        <Datestamp>{formattedDate}</Datestamp>
      </Page>

      <Links>
        <div>
          {next && (
            <PaginationLink href={next.link}>{next.text}</PaginationLink>
          )}
        </div>
        <div>
          {prev && (
            <PaginationLink href={prev.link}>{prev.text}</PaginationLink>
          )}
        </div>
      </Links>
      {annotation && (
        <AnnotationBox key={annotation.body}>
          <Close onClick={() => setAnnotation(undefined)}>Close</Close>
          <AnnotationContent>
            <ReactMarkdown children={annotation.body} />
          </AnnotationContent>
        </AnnotationBox>
      )}
    </Content>
  )
}
