const SLICE_WIDTH = 300
const DISTANCE = 200
const MAX_WIDTH = 5184 - SLICE_WIDTH - DISTANCE
const MAX_HEIGHT = 3456 - SLICE_WIDTH - DISTANCE

const charTotal = (str: string) =>
  str.split("").reduce((acc, val) => acc * val.charCodeAt(0), 1)

const getCoords = (title: string) => {
  const asciiTotal = charTotal(title)

  const x1 = asciiTotal % MAX_WIDTH
  const y1 = asciiTotal % MAX_HEIGHT

  return {
    x1: x1.toString(),
    y1: y1.toString(),
    x2: (x1 + DISTANCE).toString(),
    y2: (y1 + DISTANCE).toString(),
  }
}

export const getCoordQS = (title: string) => {
  const coords = getCoords(title)

  const qs = new URLSearchParams(coords).toString()
  return qs
}
